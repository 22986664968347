body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.light-mode {
  background-color: #fff;
  color: #333;
  transition: background-color 0.3s ease;
}

body.light-mode .badge-nomor-surat {
  background-color: #212529;
}

body.dark-mode {
  background-color: #121212;
  color: #dfdfdf;
}

body.dark-mode .dark-mode-toggle > button {
  color: #999;
}
body.dark-mode .badge-nomor-surat {
  background-color: #dfdfdf;
}
body.dark-mode .badge {
  color: #212529;
}
body.dark-mode .card-detail-surat {
  background-color: #212529;
  color: #dfdfdf;
}
body.dark-mode .card-surat {
  background-color: #212529;
  color: #dfdfdf;
}
body.dark-mode .card-ayat {
  background-color: #212529;
  color: #dfdfdf;
}

body.dark-mode .card-waktu-sholat {
  background-color: #212529;
  color: #dfdfdf;
}
body.dark-mode .card-detail-kota {
  background-color: #212529;
  color: #dfdfdf;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
ul.contact {
  list-style: none;
  margin-left: -20px;
}

.navbar {
  background-color: #f5ebbc;
  z-index: 9999;
}

.imgCarousel {
  height: 400px;
  object-fit: cover;
}

.overflow-auto::-webkit-scrollbar {
  display: none;
}
.arab {
  text-align: end;
}

.card-surat {
  cursor: pointer;
}

.badge-nomor-surat {
  border-radius: 8px;
}

.card-ayat {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.footer {
  background-color: #f5ebbc;
}
footer {
  position: absolute;
  width: 100%;
  height: 0;
}

@media only screen and (min-width: 900px) {
  .daftarSurat {
    max-height: 800px;
  }
  .scroll {
    height: 800px;
  }
  .detail-kota {
    height: 850px;
  }

  #about {
    height: 850px;
  }
}

@media only screen and (min-width: 600px) {
  .menu {
    height: 590px;
  }
  .daftarSurat {
    height: 1100px !important;
  }
  .scroll {
    height: 1100px;
  }
}
@media only screen and (max-width: 768px) {
  .menu {
    height: 470px;
  }
  .imgCarousel {
    height: 230px;
    object-fit: cover;
  }
  .daftarSurat {
    max-height: 650px;
  }

  .detail-ayat {
    height: 600px;
  }

  .audio-player {
    width: 100%;
  }
  .carousel-caption {
    font-size: 13px;
    margin-bottom: -20px;
  }
  .icon-btn {
    height: 120px;
  }

  .detail-kota {
    height: 800px;
  }
  footer {
    position: absolute;
    width: 100%;
    height: 0px;
  }
}
